<template>
    <MainPaperFormView :Type="'Template'" :Status="2" :canWatch="editableForm" > </MainPaperFormView>
    <!-- <Splitter layout="vertical" style="height: 100%;"> -->
    <!--     <SplitterPanel :size="30" :minSize="10" style="overflow: hidden;position: relative;"> -->
    <!--         <FormDataTable ref="dataTable" :Type="'draft'" :Status="2" :Loading="Loading"/> -->
    <!--     </SplitterPanel> -->
    <!--     <SplitterPanel  :size="70" :minSize="10" style="position: relative;"> -->
    <!--         <ScrollPanel class="customScrollPanel" style="position: absolute; top: 0px; left: 0px; right: 0px; bottom: 0px;"> -->
    <!--             <div style="padding: 20px;" class="" v&#45;if="editableForm"> -->
    <!--                 <editTemplateForm /> -->
    <!--                 <template v&#45;if="showSpinner"> -->
    <!--                     <div class="progressSpinnerContainer"> -->
    <!--                         <ProgressSpinner  /> -->
    <!--                     </div> -->
    <!--                 </template> -->
    <!--             </div> -->
    <!--             <div v&#45;else class="p&#45;p&#45;4"> -->
    <!--                 <Nico /> -->
    <!--             </div> -->
    <!--         </ScrollPanel> -->
    <!--     </SplitterPanel> -->
    <!-- </Splitter> -->
</template>

<script>
import MainPaperFormView from '@/components/MainPaperFormView'


export default {
    name: 'GarbageBox',
    components: {
        MainPaperFormView,
    },
    created() {
        // this.fetchData()
    },
    data: function() {
        return {
            Loading: false,
            Status: null,
            Type: null,
        }
    },
    computed: {
        showSpinner: {
            get: function() {
                return this.$store.getters["gridDataStore/getShowSpinner"]
            }
        },
        editableForm: {
            get: function() {
                return this.$store.getters["gridDataStore/isEditableForm"]
            },
        }
    },
    methods: {
        async fetchData() {
            this.Loading = true
            this.$store.dispatch('gridDataStore/fetchGridDataList', {Type: "draft", "Status": this.$constants.Status.Garbage})
            this.Loading = false
        },
    }
}
</script>


